import React from "react"
import { sizes, borderRadius } from "../style/design-system"

export default ({ className, invert, color }) => (
  <div
    className={[className, `chevron-animate`].join(` `)}
    style={{
      background: invert ? `transparent` : color,
      width: sizes["xl"],
      height: sizes["xl"],
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      borderRadius: borderRadius["sm"],
    }}
  >
    <div
      style={{
        width: sizes["md"],
        height: `2px`,
        background: invert ? color : `white`,
      }}
    ></div>
  </div>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 24 24"
  //   className={className}
  // >
  //   <path fill="none" d="M0 0h24v24H0V0z" />
  //   <path
  //     fill={colors["gray"]["800"]}
  //     d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"
  //   />
  // </svg>
)
