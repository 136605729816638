import "intersection-observer"
import "whatwg-fetch"
import "array-flat-polyfill"
import React from "react"

// Adding "reset type" styles here, will be extracted when building the javascript
// https://www.gatsbyjs.org/docs/creating-global-styles/#add-global-styles-with-css-files-and-no-layout-component
import "./src/style/normalize.css"
import "./src/style/box-sizing.css"
import "./src/style/resets.css"

import PageWrapper from "./src/components/page-wrapper"

export { default as wrapRootElement } from "./src/state/redux-wrapper"

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper>{element}</PageWrapper>
)

export const onInitialClientRender = () => {
  const removePrintContainer = () => {
    document
      .querySelector(`body`)
      .removeChild(document.querySelector(`#print-container`))
  }
  window.addEventListener(`afterprint`, removePrintContainer)
}
