import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"

import useDebounce from "../hooks/use-debounce"

import { itemsQuery } from "../state/actions/items"
import { searchQueryChanged } from "../state/actions/search"

import SearchIcon from "./search-icon"
import CloseIcon from "./close-icon"

import {
  sizes,
  borderRadius,
  borders,
  colorAliases,
  boxShadow,
} from "../style/design-system"

const useStyles = createUseStyles({
  form: {
    width: `100%`,
    padding: `${sizes["md"]} ${sizes["xl"]}`,
    background: colorAliases["lightBackground"],
    marginBottom: 0,
  },
  container: {
    display: `flex`,
    background: `white`,
    borderRadius: borderRadius["sm"],
    boxShadow: boxShadow["inputShadow"],
    border: borders["inputBorder"],
  },
  searchIcon: {
    width: `40px`,
    height: `40px`,
    padding: sizes["xxs"],
    marginLeft: sizes["xxs"],
  },
  closeIcon: {
    width: 40,
    height: 40,
    padding: sizes["xxs"],
    marginLeft: sizes["xxs"],
    cursor: `pointer`,
  },
  searchField: {
    border: `none`,
    padding: sizes["xs"],
    width: `100%`,
    borderRadius: borderRadius["sm"],
    "&::-ms-clear": {
      display: `none`,
    },
  },
})

const Search = ({ searchQuery, changeSearchQuery, searchItems, className }) => {
  const { form, container, searchIcon, closeIcon, searchField } = useStyles()

  const [firstRender, setFirstRender] = useState(true)

  const debouncedSearchTerm = useDebounce(searchQuery, 300)

  useEffect(() => {
    if (debouncedSearchTerm.length > 2 || debouncedSearchTerm.length === 0) {
      if (!firstRender) {
        searchItems(debouncedSearchTerm)
      }
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    setFirstRender(false)
  })

  const memoizedClearSearchField = useCallback(() => {
    changeSearchQuery(``)
  }, [])
  return (
    <form
      className={[className, form].join(` `)}
      onSubmit={event => {
        event.preventDefault()
      }}
    >
      <div className={container}>
        <input
          value={searchQuery}
          onChange={e => changeSearchQuery(e.target.value)}
          className={searchField}
          type="text"
        />
        {searchQuery ? (
          <CloseIcon
            className={closeIcon}
            color={colorAliases["inputBlueGray"]}
            onClick={memoizedClearSearchField}
          ></CloseIcon>
        ) : (
          <SearchIcon
            className={searchIcon}
            color={colorAliases["inputBlueGray"]}
          />
        )}
      </div>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    searchQuery: state.searchQuery,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeSearchQuery: searchQuery => dispatch(searchQueryChanged(searchQuery)),
    searchItems: searchQuery => dispatch(itemsQuery(searchQuery)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
