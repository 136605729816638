import React from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"
import ArrowLeft from "./arrow-left"
import loadable from "@loadable/component"
//kommentar for å teste azure pipelines etter merkantil flytting.

import {
  sizes,
  colorAliases,
  borders,
  MOBILE_BREAKPOINT,
} from "../style/design-system"

const HeaderLoginAndMenu = loadable(() => import("./header-login-and-menu"), {
  ssr: false,
})

const useStyles = createUseStyles({
  header: {
    width: `100%`,
    height: 75,
    padding: `0 ${sizes["xl"]}`,
    borderBottom: borders["regular"],
    display: `flex`,
    "align-items": `center`,
    background: `white`,
    position: `relative`,
  },
  container: {
    width: `100%`,
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
    margin: 0,
    whiteSpace: `nowrap`,
    textOverflow: `ellipsis`,
    overflow: `hidden`,
    width: `calc(100% - 41px)`,
  },
  titleShorter: {
    width: `calc(100% - 141px)`,
  },
  titleHidden: {
    opacity: 0,
    pointerEvents: `none`,
  },

  backButton: {
    display: `none`,
  },
  chevronLeft: {},
  [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
    backButton: {
      display: `inline-block`,
      background: `transparent`,
      padding: `${sizes["sm"]} ${sizes["sm"]}`,
      border: `none`,
      cursor: `pointer`,
      position: `absolute`,
      top: 11,
      left: 3,
    },
    title: {
      paddingLeft: 22,
      fontSize: 24,
      width: `calc(100% - 17px)`,
    },
    titleShorter: {
      width: `calc(100% - 83px)`,
    },
    chevronLeft: {
      width: 30,
    },
  },
})

const Header = ({
  openSidebar,
  closeSidebar,
  className,
  currentHitblock,
  oidcUser,
}) => {
  const classes = useStyles()
  const isSSR = typeof window === "undefined"
  return (
    <header className={[className, classes.header].join(` `)}>
      <button
        type="button"
        className={classes.backButton}
        onClick={() => openSidebar()}
      >
        <ArrowLeft
          className={classes.chevronLeft}
          color={colorAliases["darkColor"]}
        ></ArrowLeft>
      </button>
      <div className={classes.container}>
        <h2
          title={currentHitblock}
          data-testid="page-header"
          className={[
            classes.title,
            !oidcUser ? classes.titleShorter : ``,
            currentHitblock === `none` ? classes.titleHidden : ``,
          ].join(` `)}
        >
          {currentHitblock !== `none` ? currentHitblock : `Kontohjelp`}
        </h2>
        {!isSSR && (
          <React.Suspense fallback={<div></div>}>
            <HeaderLoginAndMenu
              closeSidebar={closeSidebar}
            ></HeaderLoginAndMenu>
          </React.Suspense>
        )}
      </div>
    </header>
  )
}

const mapStateToProps = state => {
  return {
    currentHitblock: state.currentHitblock,
    oidcUser: state.oidcUser,
  }
}

export default connect(mapStateToProps, null)(Header)
