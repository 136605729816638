import React from "react"
import { sizes, borderRadius } from "../style/design-system"

export default ({ className, invert, color }) => (
  <div
    className={[className, `chevron-animate`].join(` `)}
    style={{
      background: invert ? `transparent` : color,
      width: sizes["xl"],
      height: sizes["xl"],
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      borderRadius: borderRadius["sm"],
      position: `relative`,
    }}
  >
    <div
      style={{
        width: sizes["md"],
        height: `2px`,
        background: invert ? color : `white`,
      }}
    ></div>
    <div
      style={{
        height: sizes["md"],
        width: `2px`,
        background: invert ? color : `white`,
        position: `absolute`,
        left: `50%`,
        top: `50%`,
        transform: `translate(-50%, -50%)`,
      }}
    ></div>
  </div>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   viewBox="0 0 24 24"
  //   className={className}
  // >
  //   <path opacity=".87" fill="none" d="M24 24H0V0h24v24z" />
  //   <path
  //     fill={colors["gray"]["800"]}
  //     d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"
  //   />
  // </svg>
)
