import React from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"
import { Link as RLink } from "@reach/router"
import Logo from "./logo"

import { resetApp } from "../state/actions/reset-app"

import {
  sizes,
  borderRadius,
  colors,
  colorAliases,
  fontSizes,
} from "../style/design-system"
const useStyles = createUseStyles({
  container: {
    display: `flex`,
    padding: `${sizes["md"]} ${sizes["xl"]}`,
    width: `100%`,
    background: colorAliases["lightBackground"],
    alignItems: `center`,
    height: 75,
  },
  logo: {
    width: 32,
    height: 32,
    borderRadius: borderRadius["sm"],
    position: `relative`,
    marginRight: sizes["sm"],
  },
  siteTitle: {
    fontSize: fontSizes["2xl"],
    fontFamily: `Futura-PT, Sans-Serif`,
    fontWeight: 400,
    textTransform: `lowercase`,
    margin: 0,
    color: colors["gray"]["700"],
  },
})

const Branding = ({ resetAllButUser }) => {
  const { container, logo, siteTitle } = useStyles()

  function reset() {
    resetAllButUser()
  }

  return (
    <div className={container}>
      <RLink
        to="/"
        onClick={() => {
          reset()
        }}
      >
        <div className={logo}>
          <Logo></Logo>
        </div>
      </RLink>
      <RLink
        style={{ textDecoration: `none` }}
        to="/"
        onClick={() => {
          reset()
        }}
      >
        <h1 className={siteTitle}>Kontohjelp</h1>
        <h2 style={{ display: "none" }}>migration</h2>
      </RLink>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    resetAllButUser: () => dispatch(resetApp()),
  }
}

export default connect(null, mapDispatchToProps)(Branding)
