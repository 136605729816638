import { combineReducers } from "redux"

import { RESET_APP } from "../actions/reset-app"

import {
  items,
  itemsCategoryCount,
  itemsHasErrored,
  itemsIsLoading,
  itemsCategoryFilter,
  itemsShowCategoryCount,
} from "./items"
import { searchQuery, searchExecutedTimes } from "./search"
import { expandedCategories } from "./category"
import { expandedHits, hitToScroll, visibleHits } from "./hit"
import { currentHitblock, expandedHitblocks } from "./hitblock"
import { hitwords } from "./hitwords"
import { oidcUser, oidcUserManager } from "./oidc"
import { diblinkToShow } from "./dib-link"
import { renderForEmail } from "./email"

const appReducer = combineReducers({
  items,
  itemsCategoryCount,
  itemsShowCategoryCount,
  itemsCategoryFilter,
  itemsHasErrored,
  itemsIsLoading,
  searchQuery,
  searchExecutedTimes,
  expandedCategories,
  expandedHits,
  hitToScroll,
  visibleHits,
  currentHitblock,
  expandedHitblocks,
  hitwords,
  oidcUser,
  oidcUserManager,
  diblinkToShow,
  renderForEmail,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    const { oidcUser, oidcUserManager } = state
    state = { oidcUser, oidcUserManager }
  }

  return appReducer(state, action)
}

export default rootReducer
