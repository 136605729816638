import { SEARCH_QUERY_CHANGED, SEARCH_EXECUTED } from "../actions/search"

export function searchQuery(state = ``, action) {
  switch (action.type) {
    case SEARCH_QUERY_CHANGED:
      return action.searchQuery
    default:
      return state
  }
}

export function searchExecutedTimes(state = 0, action) {
  switch (action.type) {
    case SEARCH_EXECUTED:
      if (action.searchExecuted) return ++state
      else return 0
    default:
      return state
  }
}
