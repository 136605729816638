import React from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"

import { itemsFilteredByCategory } from "../state/actions/items"
import {
  colors,
  colorAliases,
  borders,
  sizes,
  fontSizes,
} from "../style/design-system"

const useStyles = createUseStyles({
  container: {
    display: `flex`,
    padding: `0 ${sizes["xl"]}`,
    borderBottom: borders["regular"],
  },
  radioInput: {
    opacity: 0,
    position: `absolute`,
    "&:checked + label": {
      borderBottom: borders["highlight"],
      fontWeight: 700,
    },
    "&:disabled + label": {
      color: colors["gray2"]["500"],
    },
  },
  label: {
    display: `inline-block`,
    padding: `${sizes["md"]} 0 ${sizes["lg"]} 0`,
    textAlign: `center`,
    cursor: `pointer`,
    marginBottom: -1,
    fontSize: fontSizes["lg"],
  },
})

const Filters = ({
  itemsCategoryFilter,
  itemsCategoryCount,
  itemsShowCategoryCount,
  itemsHasErrored,
  filterItems,
}) => {
  const { container, radioInput, label } = useStyles()

  const handleChange = e => {
    filterItems(e.target.value)
  }

  return (
    <div className={container}>
      <input
        className={radioInput}
        type="radio"
        name="filters"
        id="Emner"
        value="kontering"
        checked={!itemsHasErrored && itemsCategoryFilter === `kontering`}
        onChange={handleChange}
        disabled={itemsHasErrored}
      />
      <label
        className={label}
        style={{ marginRight: sizes["xl"] }}
        htmlFor="Emner"
      >
        Emner{" "}
        {itemsShowCategoryCount && !itemsHasErrored
          ? `(${itemsCategoryCount.emnerCount})`
          : null}
      </label>

      <input
        className={radioInput}
        type="radio"
        name="filters"
        id="Konto"
        value="kontoplan"
        checked={!itemsHasErrored && itemsCategoryFilter === `kontoplan`}
        onChange={handleChange}
        disabled={itemsHasErrored}
      />
      <label className={label} style={{ marginRight: `auto` }} htmlFor="Konto">
        Konto{" "}
        {itemsShowCategoryCount && !itemsHasErrored
          ? `(${itemsCategoryCount.kontoCount})`
          : null}
      </label>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    itemsCategoryFilter: state.itemsCategoryFilter,
    itemsShowCategoryCount: state.itemsShowCategoryCount,
    itemsCategoryCount: state.itemsCategoryCount,
    itemsHasErrored: state.itemsHasErrored,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterItems: category => dispatch(itemsFilteredByCategory(category)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters)
