export const HITBLOCK_SET_CURRENT = `HITBLOCK_SET_CURRENT`
export const HITBLOCK_TOGGLED = `HITBLOCK_TOGGLED`
export const HITBLOCK_OPENED = `HITBLOCK_OPENED`
export const HITBLOCKS_OPENED = `HITBLOCKS_OPENED`
export const HITBLOCK_CLOSED = `HITBLOCK_CLOSED`
export const HITBLOCKS_CLOSED_ALL = `HITBLOCKS_CLOSED_ALL`

export function hitblockSetCurrent(hitblock) {
  return {
    type: HITBLOCK_SET_CURRENT,
    hitblock,
  }
}

export function hitblockToggled(hitblock) {
  return {
    type: HITBLOCK_TOGGLED,
    hitblock,
  }
}

export function hitblockOpened(hitblock) {
  return {
    type: HITBLOCK_OPENED,
    hitblock,
  }
}

export function hitblockClosed(hitblock) {
  return {
    type: HITBLOCK_CLOSED,
    hitblock,
  }
}

export function hitblocksOpened(hitblocks) {
  return {
    type: HITBLOCKS_OPENED,
    hitblocks,
  }
}

export function hitblocksClosedAll() {
  return {
    type: HITBLOCKS_CLOSED_ALL,
  }
}
