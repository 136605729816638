import {
  OIDC_ADD_USER,
  OIDC_REMOVE_USER,
  OIDC_ADD_USER_MANAGER,
} from "../actions/oidc"

export function oidcUserManager(state = null, action) {
  switch (action.type) {
    case OIDC_ADD_USER_MANAGER:
      return action.userManager
    default:
      return state
  }
}

export function oidcUser(state = null, action) {
  switch (action.type) {
    case OIDC_ADD_USER:
      return action.user
    case OIDC_REMOVE_USER:
      return null
    default:
      return state
  }
}
