// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-innmelding-js": () => import("./../src/pages/innmelding.js" /* webpackChunkName: "component---src-pages-innmelding-js" */),
  "component---src-pages-kalkulatorer-js": () => import("./../src/pages/kalkulatorer.js" /* webpackChunkName: "component---src-pages-kalkulatorer-js" */),
  "component---src-pages-kjop-js": () => import("./../src/pages/kjøp.js" /* webpackChunkName: "component---src-pages-kjop-js" */),
  "component---src-pages-klokkefeil-js": () => import("./../src/pages/klokkefeil.js" /* webpackChunkName: "component---src-pages-klokkefeil-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

