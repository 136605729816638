export const SEARCH_QUERY_CHANGED = `SEARCH_QUERY_CHANGED`
export const SEARCH_EXECUTED = `SEARCH_EXECUTED`

export function searchQueryChanged(searchQuery) {
  return {
    type: SEARCH_QUERY_CHANGED,
    searchQuery,
  }
}

export function searchExecuted(searchExecuted) {
  return {
    type: SEARCH_EXECUTED,
    searchExecuted,
  }
}
