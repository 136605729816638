import React, { createRef } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"
// import { Link as GLink } from "gatsby"
import { Link as RLink } from "@reach/router"

import { slugFriendly } from "../../utils"

import { sizes, colors, colorAliases, borders } from "../style/design-system"

const useStyles = createUseStyles({
  hitLink: {
    marginBottom: sizes["md"],
    "&:last-child": {
      marginBottom: sizes["xl"],
    },
  },
  link: {
    color: `inherit`,
    textDecoration: `none`,
    "&:hover": {
      textDecoration: `underline`,
    },
  },
  open: {},
  openVisible: {
    padding: `${sizes["xs"]} 0 ${sizes["xs"]} 47px`,
    margin: `-8px 0 8px -49px`,
    borderLeft: borders["highlight"],
    background: colorAliases["darkBackground"],
    fontWeight: 700,
  },
})

const Hit = ({
  closeSidebar,
  className,
  hit,
  hitblock,
  hitblockSlug,
  clickCounter,
  setClickCounter,
  scrollHit,
  currentHitblock,
  openHit,
  fromSearch,
  expandedHits,
  visibleHits,
}) => {
  const { link, hitLink, open, openVisible } = useStyles()

  const ref = createRef()
  return (
    <li
      className={
        expandedHits.includes(hit)
          ? [
              className,
              hitLink,
              hitblock === currentHitblock && visibleHits.includes(hit)
                ? openVisible
                : open,
            ].join(` `)
          : [className, hitLink].join(` `)
      }
      ref={ref}
    >
      <RLink
        to={`${hitblockSlug}/${slugFriendly(hit)}`}
        className={link}
        onClick={() => {
          scrollHit(`${clickCounter}${hitblockSlug}/${slugFriendly(hit)}`)
          setClickCounter(Math.random().toFixed(3))
          fromSearch(false)
          const location = window.decodeURIComponent(
            window.location.pathname.split(`/`).pop()
          )
          if (location === slugFriendly(hit)) {
            openHit(hit)
          }
          closeSidebar()
        }}
      >
        {hit}
      </RLink>
    </li>
  )
}

const mapStateToProps = state => {
  return {
    expandedHits: state.expandedHits,
    visibleHits: state.visibleHits,
    currentHitblock: state.currentHitblock,
  }
}

export default connect(mapStateToProps)(Hit)
