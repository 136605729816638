import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"
import { Link as RLink } from "@reach/router"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"
import jsonToReact from "./json-to-react"
import CloseIcon from "./close-icon"
import { xml2json, slugFriendly } from "../../utils"
import { diblinkClose } from "../state/actions/dib-link"

import { oidcAddUser } from "../state/actions/oidc"
import useTimout from "../hooks/use-timeout"

import {
  colors,
  colorAliases,
  borderRadius,
  fontSizes,
  sizes,
  boxShadow,
  MOBILE_BREAKPOINT,
} from "../style/design-system"

import { initialItems } from "../../generated-data/initial-items"
const { hitblocks } = JSON.parse(initialItems)

const useStyles = createUseStyles({
  actionPrompts: {
    position: `absolute`,
    top: 0,
    left: 0,
    right: 0,
    background: colorAliases["lightBackground"],
    padding: `${sizes["2xl"]} ${sizes["2xl"]} ${sizes["xl"]} ${sizes["2xl"]}`,
    borderBottom: `4px solid ${colorAliases["darkColor"]}`,
  },
  loginButton: {
    padding: 0,
    border: `0px solid ${colors["gray"]["200"]}`,
    cursor: `pointer`,
    background: colorAliases["lightBackground"],
    color: colorAliases["darkColor"],
  },
  registerLink: {
    background: colorAliases["fancyGradient"],
    textDecoration: `none`,
    textAlign: `center`,
    color: colorAliases["lightBackground"],
    padding: `${sizes["md"]} ${sizes["xl"]}`,
    height: `fit-content`,
    borderRadius: borderRadius["sm"],
    fontSize: fontSizes["2xl"],
    boxShadow: boxShadow["lg"],
    display: `block`,
    width: 243,
  },
  priceInfo: {
    margin: `${sizes["md"]} 0 0 0`,
  },
  logInArea: {
    display: `flex`,
    alignItems: `baseline`,
  },
  topicTitle: {
    marginBottom: sizes["xl"],
  },
  valuePropositionsContainer: {
    marginTop: 125,
    position: `absolute`,
  },
  valuePropositionArea: {
    padding: `0 ${sizes["lg"]}`,
  },
  valuePropositionTitle: {},
  valuePropositionText: {},
  overlay: {
    opacity: 0,
    transition: `opacity 300ms ease-out`,
  },
  showOverlay: {
    position: `fixed`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: colorAliases["overlayColor"],
    opacity: `.3`,
    zIndex: 4,
  },
  show: {
    transform: `translateX(0vw) !important`,
  },
  chatButton: {
    background: `none`,
    border: `none`,
    textDecoration: `underline`,
    color: colorAliases["linkColor"],
    "&:after": {
      content: `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iIzZjOThjNiIgZD0iTTE5IDE5SDVWNWg3VjNINWMtMS4xMSAwLTIgLjktMiAydjE0YzAgMS4xLjg5IDIgMiAyaDE0YzEuMSAwIDItLjkgMi0ydi03aC0ydjd6TTE0IDN2MmgzLjU5bC05LjgzIDkuODMgMS40MSAxLjQxTDE5IDYuNDFWMTBoMlYzaC03eiIvPjwvc3ZnPg==)`,
      marginLeft: 2,
    },
  },
  [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
    diblinkDisplay: {
      width: `calc(100vw - 40px)`,
      height: `calc(100vh - 100px)`,
      top: 80,
    },
    secondDiblinkDisplay: {
      width: `calc(100vw - 40px)`,
      height: `calc(100vh - 100px)`,
      top: 80,
    },
    valuePropositionsContainer: {
      marginTop: 160,
    },
  },
})

const DibLinkDisplay = ({
  diblinkToShow,
  oidcUser,
  oidcUserManager,
  addUser,
  closeDibLink,
}) => {
  const [linkToInternal, setLinkToInternal] = useState(``)
  const [linkToDib, setLinkToDib] = useState(null)
  const [noAccess, setNoAccess] = useState(false)
  const [data, setData] = useState(null)
  const [showloginMessage, setShowLoginMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [nag, setNag] = useState(false)
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    // doing this superhacky thing because no time to debug why jss applies wrong className for
    // diblinkDisplay, innerContainer and closeIcon
    // so after firstRender, we apply copies of those classes instead:
    // secondDiblinkDisplay, secondInnerContainer and secondCloseIcon :$
    if (firstRender) setFirstRender(false)
  }, [firstRender])

  const {
    actionPrompts,
    loginButton,
    registerLink,
    priceInfo,
    logInArea,
    topicTitle,
    valuePropositionsContainer,
    valuePropositionArea,
    valuePropositionTitle,
    valuePropositionText,
    showOverlay,
    overlay,
    show,
    chatButton,
  } = useStyles()

  const valuePropositions = [
    {
      title: `Alt til regnskapet`,
      text: `Hva er riktig konto for blomster? Er det fradrag for mva? Må det innberettes som lønn? Har bedriften skattemessig fradrag? Regnskap er ikke alltid enkelt, men kontohjelp.no har svarene.`,
    },
    {
      title: `17 000 brukere`,
      text: `Kontohjelp brukes av alt fra enkeltpersonforetak og små aksjeselskaper til regnskapsførere og regnskapsavdelinger i store selskaper.`,
    },
    {
      title: `Før regnskapet selv`,
      text: `Stadig flere små selskaper ønsker å ta bokføringen selv. Med kontohjelp.no får du raske svar på de aller fleste problemstillinger innen regnskap.`,
    },
  ]

  const login = () => {
    window.sessionStorage.setItem(
      `loginLocation`,
      window.decodeURIComponent(window.location.pathname)
    )
    oidcUserManager.signinRedirect()
  }

  useTimout(() => {
    if (!oidcUser) setNag(true)
  }, 30000)

  useEffect(() => {
    setShowLoginMessage(false)
    setIsError(false)
    setData(null)
    setLinkToInternal(``)
    setLinkToDib(null)
    setNoAccess(false)
    if (!oidcUser) {
      setShowLoginMessage(true)
    } else if (diblinkToShow) {
      setIsLoading(true)
      let internalTopic = null
      if (diblinkToShow.type === `linkto`)
        internalTopic = hitblocks.find(
          hitblock => hitblock.tid === diblinkToShow.topicId
        )

      if (internalTopic) {
        if (diblinkToShow.segmentId) {
          fetch(
            `${
              window.location.origin
            }/bookmarks/${diblinkToShow.topicId.replace(/{|}/g, ``)}_${
              diblinkToShow.segmentId
            }.json`
          )
            .then(res => res.json())
            .then(data => {
              let dataShaped = {
                packages: [
                  {
                    topics: [
                      {
                        topic_id: diblinkToShow.topicId,
                        name: internalTopic.title,
                        docparts: [
                          {
                            document: data.html
                              .substring(5, data.html.length - 6)
                              .trim(),
                          },
                        ],
                      },
                    ],
                  },
                ],
              }
              setIsLoading(false)
              setData(dataShaped)
            })
            .catch(() => {
              setIsLoading(false)
              setLinkToInternal(
                `${slugFriendly(internalTopic.category)}/${slugFriendly(
                  internalTopic.title
                )}`
              )
            })
        } else {
          setIsLoading(false)
          setLinkToInternal(
            `${slugFriendly(internalTopic.category)}/${slugFriendly(
              internalTopic.title
            )}`
          )
        }
      } else {
        const body = {
          topic_id: diblinkToShow.topicId,
          segment_id: diblinkToShow.segmentId,
          ref_id: diblinkToShow.refId,
        }
        const url = `${process.env.GATSBY_KONTOHJELP_API_URL}/GetDocument`
        oidcUserManager.getUser().then(user => {
          fetch(url, {
            method: `POST`,
            headers: {
              "Content-Type": `application/json`,
              Authorization: `Bearer ${user.access_token}`,
            },
            body: '"' + diblinkToShow.id + '"',
          })
            .then(response => {
              if (response.ok) {
                return response
              } else {
                throw Error(response.status)
              }
            })
            .then(
              response =>
                response.json().then(data => {
                  setIsLoading(false)
                  if (diblinkToShow.type === `linkto`) {
                    if (data.dibCredentials) {
                      data &&
                      data.packages &&
                      data.packages[0] &&
                      data.packages[0].topics
                        ? setLinkToDib({
                            topicId: data.packages[0].topics[0].topic_id,
                            name: data.packages[0].topics[0].name,
                          })
                        : setLinkToDib({ topicId: ``, name: `` })
                    } else {
                      setNoAccess(true)
                    }
                  } else if (
                    diblinkToShow.type === `diblink` &&
                    !data.dibCredentials
                  ) {
                    setNoAccess(true)
                  } else {
                    setData(data)
                  }
                }),
              error => {
                if (error.message === `401`) {
                  // console.log(`401 situation!`)
                  return oidcUserManager
                    .signinSilent()
                    .then(
                      user => {
                        // console.log(`Prøver igjen med... `, user)
                        if (user) {
                          addUser(user)
                          return fetch(url, {
                            method: `POST`,
                            headers: {
                              "Content-Type": `application/json`,
                              Authorization: `Bearer ${user.access_token}`,
                            },
                            body: '"' + diblinkToShow.id + '"',
                          })
                            .then(response => {
                              if (response.ok) {
                                return response
                              } else {
                                throw Error(response.status)
                              }
                            })
                            .then(
                              response =>
                                response.json().then(data => {
                                  setIsLoading(false)
                                  if (diblinkToShow.type === `linkto`) {
                                    if (data.dibCredentials) {
                                      data &&
                                      data.packages &&
                                      data.packages[0] &&
                                      data.packages[0].topics
                                        ? setLinkToDib({
                                            topicId:
                                              data.packages[0].topics[0]
                                                .topic_id,
                                            name:
                                              data.packages[0].topics[0].name,
                                          })
                                        : setLinkToDib({
                                            topicId: ``,
                                            name: ``,
                                          })
                                    } else {
                                      setNoAccess(true)
                                    }
                                  } else if (
                                    diblinkToShow.type === `diblink` &&
                                    !data.dibCredentials
                                  ) {
                                    setNoAccess(true)
                                  } else {
                                    setData(data)
                                  }
                                }),
                              error => {
                                // console.log(`second try did not work either...`)
                                console.error(error)
                                setIsLoading(false)
                                setIsError(true)
                                throw Error(error.message)
                              }
                            )
                        } else {
                          throw Error(`signinSilent failed, no user returned`)
                        }
                      },
                      error => {
                        // console.log(`signinsilent failed, threw some error`)
                        // console.log(
                        //   `This is the error signinSilent threw: `,
                        //   error
                        // )
                        throw Error(`Try 3 please`)
                      }
                    )
                    .catch(error => {
                      if (error.message === `Try 3 please`) {
                        return oidcUserManager.getUser().then(u => {
                          fetch(url, {
                            method: `POST`,
                            headers: {
                              "Content-Type": `application/json`,
                              Authorization: `Bearer ${u.access_token}`,
                            },
                            body: '"' + diblinkToShow.id + '"',
                          })
                            .then(response => {
                              if (response.ok) {
                                return response
                              } else {
                                throw Error(response.status)
                              }
                            })
                            .then(response => response.json())
                            .then(data => {
                              setIsLoading(false)
                              if (diblinkToShow.type === `linkto`) {
                                if (data.dibCredentials) {
                                  data &&
                                  data.packages &&
                                  data.packages[0] &&
                                  data.packages[0].topics
                                    ? setLinkToDib({
                                        topicId:
                                          data.packages[0].topics[0].topic_id,
                                        name: data.packages[0].topics[0].name,
                                      })
                                    : setLinkToDib({ topicId: ``, name: `` })
                                } else {
                                  setNoAccess(true)
                                }
                              } else if (
                                diblinkToShow.type === `diblink` &&
                                !data.dibCredentials
                              ) {
                                setNoAccess(true)
                              } else {
                                setData(data)
                              }
                            })
                            .catch(error => {
                              // console.log(`failed 3rd time also`)
                              setIsLoading(false)
                              setIsError(true)
                              throw Error(error.message)
                            })
                        })
                      } else {
                        throw Error(
                          `Not sure where this happened: ${error.message}`
                        )
                      }
                    })
                } else {
                  // console.log(error)
                  // console.log(`The error was not 401`)
                  setIsLoading(false)
                  setIsError(true)
                  throw Error(error.message)
                }
              }
            )
            .catch(error => {
              // console.log(
              //   `Something failed, not super sure where... but here you go: `,
              //   error
              // )
            })
            .then(() => {
              // console.log(`All done...`)
            })
        })
      }
    }
  }, [diblinkToShow])

  return (
    <>
      <div
        className={
          diblinkToShow || nag ? [overlay, showOverlay].join(` `) : overlay
        }
        onClick={() => {
          closeDibLink()
        }}
      ></div>
      <aside
        className={diblinkToShow || nag ? show : ``}
        style={{
          width: `90vw`,
          maxWidth: `800px`,
          height: `calc(100vh - 101px - 40px)`, //101px = height of header.
          background: `white`,
          position: `fixed`,
          right: 20,
          top: 121,
          transform: `translateX(105vw)`,
          transition: `transform 300ms ease-out`,
          borderRadius: borderRadius["sm"],
          overflow: `auto`,
          padding: `${sizes["2xl"]} ${sizes["lg"]} ${sizes["lg"]} ${sizes["lg"]}`,
          boxShadow: boxShadow["lg"],
          display: `flex`,
          alignItems: `center`,
          zIndex: 4,
        }}
      >
        <div
          style={{
            width: 28,
            height: 28,
            position: `absolute`,
            right: 16,
            top: 16,
            cursor: `pointer`,
          }}
          onClick={() => {
            closeDibLink()
          }}
        >
          <CloseIcon></CloseIcon>
        </div>
        <div style={{ height: `100%`, width: `100%`, overflow: `auto` }}>
          {showloginMessage ? (
            <div>
              <div className={actionPrompts}>
                <div>
                  <a
                    href="https://login.dib.no/kontohjelp/kj%C3%B8p"
                    className={registerLink}
                    data-testid="register-link"
                  >
                    Registrer deg
                  </a>
                </div>
                <div>
                  <div className={logInArea}>
                    <p className={priceInfo}>
                      Kontohjelp koster kun 1350 kr i året.&nbsp; Allerede
                      bruker?&nbsp;
                      <button
                        data-testid="login-button"
                        type="button"
                        className={loginButton}
                        onClick={() => login()}
                      >
                        Logg inn
                      </button>
                    </p>
                  </div>
                </div>
              </div>

              <div className={valuePropositionsContainer}>
                {valuePropositions.map(({ title, text }, i) => (
                  <div className={valuePropositionArea} key={i}>
                    <h2 className={valuePropositionTitle}>{title}</h2>
                    <p className={valuePropositionText}>{text}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {isLoading ? <h3 onClick={() => closeDibLink()}>Laster...</h3> : null}

          {isError ? (
            <h3 data-testid="something-went-wrong">
              Noe gikk galt. Kontakt support.
            </h3>
          ) : null}

          {linkToInternal ? (
            <h3>
              Gå til{" "}
              <RLink to={linkToInternal} onClick={() => closeDibLink()}>
                {linkToInternal}
              </RLink>
            </h3>
          ) : null}

          {noAccess ? (
            <div>
              <h3>Dette innholdet er kun tilgjengelig i dib.</h3>
              <h3>Har du ikke dib?</h3>
              <p>
                <a
                  href="https://app.dib.no/demo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prøv dib her
                </a>{" "}
                eller{" "}
                <button className={chatButton}>kontakt oss i chatten</button>
              </p>
            </div>
          ) : null}

          {linkToDib ? (
            <div>
              <h3>Dette innholdet er kun tilgjengelig i dib.</h3>
              <p>
                Gå til dib:{" "}
                <a
                  href={`https://app.dib.no/tema/frakontohjelp/${linkToDib.topicId}`}
                >
                  {linkToDib.name}
                </a>
              </p>
            </div>
          ) : null}

          {data && data.packages
            ? data.packages.map(({ topics }) => {
                return topics.map(({ docparts, name }, index) => {
                  return (
                    <div key={index}>
                      {name ? <h2 className={topicTitle}>{name}</h2> : null}
                      {docparts.map(({ document }, i) => {
                        let ret

                        document
                          ? (ret = jsonToReact({
                              json: JSON.parse(xml2json(document)).children[0],
                              hitwords: null,
                              ignoreDiblinks: true,
                              key: i,
                            }))
                          : (ret = null)

                        return ret
                      })}
                    </div>
                  )
                })
              })
            : null}
        </div>
      </aside>
    </>
  )
}

const mapStateToProps = state => {
  return {
    diblinkToShow: state.diblinkToShow,
    oidcUser: state.oidcUser,
    oidcUserManager: state.oidcUserManager,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeDibLink: () => dispatch(diblinkClose()),
    addUser: user => dispatch(oidcAddUser(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DibLinkDisplay)
