import { HITWORDS_CHANGED } from "../actions/hitwords"

export function hitwords(state = [], action) {
  switch (action.type) {
    case HITWORDS_CHANGED:
      return action.hitwords
    default:
      return state
  }
}
