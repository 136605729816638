export const HIT_TOGGLED = `HIT_TOGGLED`
export const HIT_OPENED = `HIT_OPENED`
export const HIT_SCROLL = `HIT_SCROLL`
export const HIT_VISIBLE = `HIT_VISIBLE`
export const HIT_NOT_VISIBLE = `HIT_NOT_VISIBLE`

export function hitToggled(hit) {
  return {
    type: HIT_TOGGLED,
    hit,
  }
}

export function hitOpened(hit) {
  return {
    type: HIT_OPENED,
    hit,
  }
}

export function hitScroll(hitSlug) {
  return {
    type: HIT_SCROLL,
    hitSlug,
  }
}

export function hitVisible(hit) {
  return {
    type: HIT_VISIBLE,
    hit,
  }
}

export function hitNotVisible(hit) {
  return {
    type: HIT_NOT_VISIBLE,
    hit,
  }
}
