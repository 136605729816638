import {
  HITBLOCK_SET_CURRENT,
  HITBLOCK_TOGGLED,
  HITBLOCK_OPENED,
  HITBLOCKS_OPENED,
  HITBLOCK_CLOSED,
  HITBLOCKS_CLOSED_ALL,
} from "../actions/hitblock"

export function currentHitblock(state = ``, action) {
  switch (action.type) {
    case HITBLOCK_SET_CURRENT:
      return action.hitblock
    default:
      return state
  }
}

export function expandedHitblocks(state = [], action) {
  let index

  switch (action.type) {
    case HITBLOCKS_OPENED:
      return action.hitblocks
    case HITBLOCK_OPENED:
      if (state.includes(action.hitblock)) return state
      return [...state, action.hitblock]
    case HITBLOCK_TOGGLED:
      index = state.indexOf(action.hitblock)
      if (index === -1) return [...state, action.hitblock]
      return [...state.slice(0, index), ...state.slice(index + 1)]
    case HITBLOCK_CLOSED:
      index = state.indexOf(action.hitblock)
      if (index === -1) return state
      return [...state.slice(0, index), ...state.slice(index + 1)]
    case HITBLOCKS_CLOSED_ALL:
      return []
    default:
      return state
  }
}
