export const OIDC_ADD_USER = `OIDC_ADD_USER`
export const OIDC_REMOVE_USER = `OIDC_REMOVE_USER`
export const OIDC_ADD_USER_MANAGER = `OIDC_ADD_USER_MANAGER`

export function oidcAddUser(user) {
  return {
    type: OIDC_ADD_USER,
    user,
  }
}

export function oidcRemoveUser() {
  return {
    type: OIDC_REMOVE_USER,
  }
}

export function oidcAddUserManager(userManager) {
  return {
    type: OIDC_ADD_USER_MANAGER,
    userManager,
  }
}
