export const DIB_LINK_SHOW = `DIB_LINK_SHOW`
export const DIB_LINK_CLOSE = `DIB_LINK_CLOSE`

export function diblinkShow(linkObj) {
  return {
    type: DIB_LINK_SHOW,
    linkObj,
  }
}

export function diblinkClose() {
  return {
    type: DIB_LINK_CLOSE,
  }
}
