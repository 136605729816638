export const MOBILE_BREAKPOINT = 768

export const fontSizes = {
  xs: `12px`,
  sm: `14px`,
  md: `16px`,
  lg: `18px`,
  xl: `20px`,
  "2xl": `24px`,
  "3xl": `30px`,
  "4xl": `36px`,
  "5xl": `48px`,
  "6xl": `60px`,
  "7xl": `72px`,
}

export const sizes = {
  xxs: `4px`,
  xs: `8px`,
  sm: `12px`,
  md: `16px`,
  lg: `24px`,
  xl: `32px`,
  "2xl": `48px`,
  "3xl": `64px`,
  "4xl": `96px`,
  "5xl": `128px`,
  "6xl": `192px`,
  "7xl": `256px`,
  "8xl": `384px`,
  "9xl": `512px`,
  "10xl": `640px`,
  "11xl": `768px`,
  siteWidth: `1200px`,
}

export const colors = {
  gray: {
    "50": `#F7F7F7`,
    "75": `#E1E1E18C`,
    "100": `#E1E1E1`,
    "200": `#CFCFCF`,
    "300": `#B1B1B1`,
    "400": `#9E9E9E`,
    "500": `#7E7E7E`,
    "600": `#626262`,
    "700": `#515151`,
    "800": `#3B3B3B`,
    "900": `#222222`,
  },
  blue: {
    "50": `#E6F6FF`,
    "100": `#BAE3FF`,
    "200": `#7CC4FA`,
    "300": `#47A3F3`,
    "400": `#2186EB`,
    "500": `#0967D2`,
    "600": `#0552B5`,
    "700": `#03449E`,
    "800": `#01337D`,
    "900": `#002159`,
  },
  gray2: {
    "50": `#F0F4F8`,
    "100": `#D9E2EC`,
    "200": `#BCCCDC`,
    "300": `#9FB3C8`,
    "400": `#829AB1`,
    "500": `#627D98`,
    "600": `#486581`,
    "700": `#334E68`,
    "800": `#243B53`,
    "900": `#102A43`,
  },
  red: {
    "300": `#FEB2B2`,
    "600": `#F56565`,
  },
}

export const colorAliases = {
  lightBackground: `#F5FBFF`,
  darkBackground: `#DAF0FF`,
  veryDarkBackground: `#002e4d`,
  darkColor: `#0077C5`,
  inputBlueGray: `#B5CFE1`,
  overlayColor: `#004370`,
  linkColor: `#0077c5`,
  fancyGradient: `linear-gradient(226.27deg, #CC26FF 0%, #0077C5 100%, #0077C5 100%)`,
  superLightBackground: `#fafdff`,
  textColor: colors["gray"]["700"],
}

export const borders = {
  regular: `1px solid ${colors["gray2"]["100"]}`,
  regularBold: `2px solid ${colors["gray2"]["100"]}`,
  highlight: `4px solid ${colorAliases["darkColor"]}`,
  inputBorder: `1px solid ${colorAliases["inputBlueGray"]}`,
  contentRegular: `1px solid ${colors["gray"]["200"]}`,
}

export const borderRadius = {
  sm: `.125rem`,
  md: `.25rem`,
  lg: `.5rem`,
  full: `9999px`,
}

export const boxShadow = {
  sm: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
  md: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  "md-extended-top": `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 -1px 6px -3px rgba(0,0,0,.2)`,
  lg: `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
  xl: `0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)`,
  "2xl": `0 25px 50px -12px rgba(0, 0, 0, 0.25)`,
  inputShadow: `0 1px 3px 0 rgba(0, 0, 0, .1)`,
}
