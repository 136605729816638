import React from "react"

export default ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 114 114"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="34.9731445%"
        y1="35.4522705%"
        x2="9.6496582%"
        y2="74.4171143%"
        id="linearGradient-1"
      >
        <stop stopColor="#1B5FAA" offset="0%"></stop>
        <stop stopColor="#0077C5" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="119.882202%"
        y1="-22.5189209%"
        x2="9.66949463%"
        y2="65.8004761%"
        id="linearGradient-2"
      >
        <stop stopColor="#CC26FF" offset="0%"></stop>
        <stop stopColor="#0077C5" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-239.000000, -760.000000)" id="Logo_Color">
        <g transform="translate(127.000000, 124.000000)">
          <g id="Mark" transform="translate(112.000000, 636.000000)">
            <polygon
              id="Rectangle"
              fill="url(#linearGradient-1)"
              transform="translate(56.671993, 56.671993) rotate(-90.000000) translate(-56.671993, -56.671993) "
              points="0 0 113.343986 0 0 113.343986"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="url(#linearGradient-2)"
              points="0 0 113.343986 0 0 113.343986"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
