import { hitwordsChanged } from "./hitwords"
import { hitblocksOpened, hitblocksClosedAll } from "./hitblock"
import { hitblocksProcessor } from "../../../utils"
import { searchExecuted } from "../actions/search"

export const ITEMS_HAS_ERRORED = `ITEMS_HAS_ERRORED`
export const ITEMS_IS_LOADING = `ITEMS_IS_LOADING`
export const ITEMS_FILTERED_BY_CATEGORY = `ITEMS_FILTERED_BY_CATEGORY`
export const ITEMS_CATEGORY_COUNT = `ITEMS_CATEGORY_COUNT`
export const ITEMS_SHOW_CATEGORY_COUNT = `ITEMS_SHOW_CATEGORY_COUNT`
export const ITEMS_QUERY_SUCCESS = `ITEMS_QUERY_SUCCESS`
export const ITEMS_RESET = `ITEMS_RESET`

export function itemsHasErrored(bool) {
  return {
    type: ITEMS_HAS_ERRORED,
    hasErrored: bool,
  }
}

export function itemsIsLoading(bool) {
  return {
    type: ITEMS_IS_LOADING,
    isLoading: bool,
  }
}

export function itemsFilteredByCategory(category) {
  return {
    type: ITEMS_FILTERED_BY_CATEGORY,
    category,
  }
}

export function itemsCategoryCount(countObj) {
  return {
    type: ITEMS_CATEGORY_COUNT,
    countObj,
  }
}

export function itemsShowCategoryCount(showOrNot) {
  return {
    type: ITEMS_SHOW_CATEGORY_COUNT,
    showOrNot,
  }
}

export function itemsQuerySuccess(items) {
  return {
    type: ITEMS_QUERY_SUCCESS,
    items,
  }
}

export function itemsReset() {
  return {
    type: ITEMS_RESET,
  }
}

export function itemsQuery(query) {
  const url = `${process.env.GATSBY_KONTOHJELP_API_URL}/Search`
  return dispatch => {
    if (!query) {
      dispatch(searchExecuted(false))
      dispatch(itemsReset())
      dispatch(itemsHasErrored(false))
      dispatch(itemsShowCategoryCount(false))
      dispatch(hitwordsChanged([]))
    } else {
      dispatch(itemsIsLoading(true))

      fetch(url, {
        method: `POST`,
        headers: {
          "Content-Type": `application/json-patch+json`,
          "kontohjelp-cs": process.env.KONTOHJELPCS,
        },
        body: '"' + query + '"',
      })
        .then(response => {
          if (!response.ok) throw Error(response.statusText)

          dispatch(itemsIsLoading(false))

          return response
        })
        .then(response => response.json())
        .then(data => {
          dispatch(searchExecuted(true))
          const {
            hitblocks,
            emnerCount,
            kontoCount,
            numberOfHits,
            hitblockTitles,
          } = hitblocksProcessor({ data, initialData: false })

          numberOfHits < 30
            ? dispatch(hitblocksOpened(hitblockTitles))
            : dispatch(hitblocksClosedAll())

          dispatch(itemsQuerySuccess(hitblocks))
          dispatch(itemsFilteredByCategory(`all`))
          dispatch(itemsCategoryCount({ emnerCount, kontoCount }))
          dispatch(itemsShowCategoryCount(true))
          let hitwords = []
          if (query.trim()) hitwords = query.split(` `).filter(word => !!word)

          dispatch(hitwordsChanged(hitwords))

          dispatch(itemsHasErrored(false))
        })
        .catch(() => {
          dispatch(itemsHasErrored(true))
        })
    }
  }
}
