import React, { useState, useEffect, useCallback } from "react"
import { createUseStyles } from "react-jss"

import Header from "../components/header"
import Branding from "../components/branding"
import Search from "../components/search"
import Filters from "../components/filters"
import ResultList from "../components/result-list"
import DibLinkDisplay from "../components/dib-link-display"

import {
  colorAliases,
  borders,
  sizes,
  MOBILE_BREAKPOINT,
  boxShadow,
} from "../style/design-system"

const useStyles = createUseStyles({
  "@global": {
    a: {
      color: colorAliases["linkColor"],
      '&[target="_blank"]:after': {
        content: `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iIzZjOThjNiIgZD0iTTE5IDE5SDVWNWg3VjNINWMtMS4xMSAwLTIgLjktMiAydjE0YzAgMS4xLjg5IDIgMiAyaDE0YzEuMSAwIDItLjkgMi0ydi03aC0ydjd6TTE0IDN2MmgzLjU5bC05LjgzIDkuODMgMS40MSAxLjQxTDE5IDYuNDFWMTBoMlYzaC03eiIvPjwvc3ZnPg==)`,
        marginLeft: 2,
      },
      '&[data-iconafter="no"]:after': {
        content: "''",
        marginLeft: 0,
      },
    },
    "#print-container": {
      display: `none`,
    },
    "@media print": {
      "#___gatsby": {
        display: `none`,
      },
      "#print-container": {
        display: `initial`,
      },
    },
  },
  container: {
    display: `flex`,
    width: `100%`,
    maxWidth: `1450px`,
    margin: `0 auto`,
    height: `100%`,
    boxShadow: boxShadow["xl"],
  },
  sidebar: {
    width: `35%`,
    height: `100vh`,
    background: colorAliases["lightBackground"],
    borderRight: borders["regular"],
  },
  sidebarOpen: {},
  content: {
    width: `65%`,
    position: `relative`,
    background: `white`,
  },
  header: {},
  main: {
    padding: `${sizes["md"]} ${sizes["xl"]}`,
    height: `calc(100vh - ${84}px)`,
    overflow: `auto`,
  },
  resultList: {
    height: `calc(100vh - ${206}px)`,
    overflow: `auto`,
  },
  mobileNav: {
    display: `none`,
  },
  [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
    sidebar: {
      position: `fixed`,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: `100vw`,
      "z-index": 1,
      transform: `translateX(-100vw)`,
      transition: `transform 300ms ease-out`,
    },
    sidebarOpen: {
      transform: `translateX(0vw)`,
    },
    content: {
      width: `100vw`,
      overflow: `hidden`,
      height: `100vh`,
    },
    main: {
      position: `fixed`,
      top: 75,
      width: `100%`,
    },
  },
})

const PageWrapper = ({ children }) => {
  const {
    container,
    sidebar,
    sidebarOpen,
    content,
    header,
    main,
    resultList,
  } = useStyles()

  const [sidebarOpenState, setSidebarOpenState] = useState(true)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.location.pathname !== `/`) {
        setSidebarOpenState(false)
      }

      if (window.location.pathname === `/callback/`) {
        let loginLocation = window.sessionStorage.getItem(`loginLocation`)
        if (loginLocation && loginLocation === `/`) setSidebarOpenState(true)
      }
    }
  }, [])

  const closeSidebar = useCallback(() => {
    setSidebarOpenState(false)
  }, [sidebarOpenState])

  const openSidebar = useCallback(() => {
    setSidebarOpenState(true)
  }, [sidebarOpenState])

  return (
    <div className={container}>
      <div
        className={
          sidebarOpenState ? [sidebar, sidebarOpen].join(` `) : sidebar
        }
      >
        <Branding></Branding>
        <Search />
        <Filters />
        <ResultList closeSidebar={closeSidebar} className={resultList} />
      </div>
      <div className={content}>
        <Header
          openSidebar={openSidebar}
          closeSidebar={closeSidebar}
          className={header}
          siteTitle={`Kontohjelp`}
        />
        <main className={main} id="main-content">
          {children}
        </main>
        <DibLinkDisplay></DibLinkDisplay>
      </div>
    </div>
  )
}

export default PageWrapper
