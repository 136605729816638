import { DIB_LINK_SHOW, DIB_LINK_CLOSE } from "../actions/dib-link"

export function diblinkToShow(state = null, action) {
  switch (action.type) {
    case DIB_LINK_SHOW:
      return action.linkObj
    case DIB_LINK_CLOSE:
      return null
    default:
      return state
  }
}
