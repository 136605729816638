export const CATEGORY_EXPANDED = `CATEGORY_EXPANDED`
export const CATEGORY_CLOSED = `CATEGORY_CLOSED`

export function categoryExpanded(category) {
  return {
    type: CATEGORY_EXPANDED,
    category,
  }
}

export function categoryClosed(category) {
  return {
    type: CATEGORY_CLOSED,
    category,
  }
}
