import React from "react"
import XBox from "./x-box"
import DibLink from "./dib-link"

import {
  voidTags,
  validTags,
  highlightHitWord,
  validParentElements,
} from "../../utils"

const renameAttributes = {
  colspan: "colSpan",
  rowspan: "rowSpan",
  "xml:space": "xmlSpace",
}

const stripAttributes = [
  "style",
  "align",
  "height",
  "onclick",
  "serialnumber",
  "valign",
  "width",
]

const customElementNames = [`x-box`]
const parsingRules = [
  {
    shouldParse: ({ name }) => name === `style`,
    parser: () => null,
  },
  {
    shouldParse: ({ name }) => name === `text`,
    parser: ({ json, hitwords }) => highlightHitWord(json[`text`], hitwords),
  },
  {
    shouldParse: ({ name }) => name === `x-box`,
    parser: ({ json, failSlug, key, ignoreDiblinks }) => {
      return (
        <XBox
          boxContent={json.children}
          key={key}
          failSlug={failSlug}
          ignoreDiblinks={ignoreDiblinks}
        />
      )
    },
  },
  {
    shouldParse: ({ name }) => name === `a`,
    parser: ({
      dataprops,
      json,
      ignoreDiblinks,
      hitwords,
      attributes,
      key,
    }) => {
      if (!json.children) return null
      if (
        (attributes.diblink || !json.attributes.href?.startsWith("http")) &&
        !ignoreDiblinks
      ) {
        return (
          <DibLink
            refId={dataprops[`data-refid`] || ``}
            topicId={dataprops[`data-topicid`] || ``}
            segmentId={dataprops[`data-segment`] || ``}
            type={attributes.linkto ? `linkto` : `diblink`}
            text={json.children[0].text}
            id={attributes.id}
            hitwords={hitwords}
            key={key}
          ></DibLink>
        )
      } else if (ignoreDiblinks) {
        return (
          <span
            key={key}
            title={json.attributes.title ? json.attributes.title : null}
          >
            {json.children[0].text}
          </span>
        )
      } else {
        return (
          <a
            key={key}
            href={json.attributes.href ? json.attributes.href : null}
            title={json.attributes.title ? json.attributes.title : null}
            target={json.attributes.target ? json.attributes.target : null}
            rel="noopener noreferrer"
          >
            {json.children[0].text}
          </a>
        )
      }
    },
  },
  {
    shouldParse: ({ name }) => name && validTags.includes(name),
    parser: ({
      name,
      dataprops,
      json,
      hitwords,
      ignoreDiblinks,
      failSlug,
      attributes,
      key,
    }) => {
      const heavyHeadings = [`h1`, `h2`, `h3`, `h4`, `h5`]
      if (heavyHeadings.includes(name)) {
        name = name[0] + (parseInt(name[1], 10) + 1)
      }

      if (voidTags.includes(name)) {
        if (name === `img`) {
          const imgPath =
            attributes.src.charAt(0) === "/"
              ? attributes.src.substr(1)
              : attributes.src
          const rx = /(dibimages\/)/
          const match = imgPath.match(rx)
          const realPath =
            match && match.index === 0
              ? `https://webservice.dib.no/${imgPath}`
              : imgPath
          return <img key={key} src={realPath} width={attributes.width} />
        } else {
          return React.createElement(name, { ...dataprops, ...attributes, key })
        }
      }

      // If the node is an illegal container element for DIV elements,
      // and it has a named custom element in its list of children,
      // the node has to be converted to a DIV and its contents must be separated
      if (
        !validParentElements.includes(name) &&
        (json.children || []).filter(el =>
          customElementNames.includes(el[`name`])
        ).length > 0
      ) {
        // split children
        // name: "p", children: ["span", "x-mvatable", "span"]
        // -> [p[children: "span"], x-mvatable, p[children: "span"]
        let chunks = []
        let chunk = null
        json.children.forEach(child => {
          if (customElementNames.includes(child[`name`])) {
            chunks.push({ name: `div`, children: [child] })
            chunk = null
          } else {
            if (!chunk) {
              chunk = { name: name, children: [] }
              chunks.push(chunk)
            }
            chunk.children.push(child)
          }
        })

        return chunks.map((chunk, i) => {
          return React.createElement(
            chunk.name,
            { ...dataprops, ...attributes, key: key + "_" + i },
            (chunk.children || []).map((child, index) =>
              jsonToReact({
                json: child,
                hitwords,
                ignoreDiblinks,
                failSlug,
                key: index,
              })
            )
          )
        })
      }

      if (name === `table`) {
        return (
          <div style={{ overflowX: "auto" }} key={key}>
            {React.createElement(
              name,
              { ...dataprops, ...attributes },
              (json.children || []).map((child, index) =>
                jsonToReact({
                  json: child,
                  hitwords,
                  ignoreDiblinks,
                  failSlug,
                  key: index,
                })
              )
            )}
          </div>
        )
      }

      return React.createElement(
        name,
        { ...dataprops, ...attributes, key },
        (json.children || []).map((child, index) =>
          jsonToReact({
            json: child,
            hitwords,
            ignoreDiblinks,
            failSlug,
            key: index,
          })
        )
      )
    },
  },
]

export default function jsonToReact({
  json,
  hitwords,
  ignoreDiblinks,
  failSlug,
  key,
}) {
  const name = json[`name`] ? json[`name`] : `text`
  let dataprops = Object.keys(json["attributes"] || {})
    .filter(el => el.indexOf("data-") === 0)
    .reduce(
      (accumulator, current) =>
        Object.assign(accumulator, { [current]: json["attributes"][current] }),
      {}
    )

  let attributes = Object.keys(json["attributes"] || {})
    .filter(el => !(el.indexOf("data-") === 0))
    .reduce((accumulator, current) => {
      if (renameAttributes[current])
        return {
          ...accumulator,
          [renameAttributes[current]]: json.attributes[current],
        }
      if (stripAttributes.includes(current)) return accumulator
      if (current == `class` && json.attributes[current].includes(`diblink`))
        return {
          ...accumulator,
          diblink: true,
        }
      if (current == `class` && json.attributes[current].includes(`linkto`))
        return {
          ...accumulator,
          linkto: true,
        }
      if (current === `class`) return accumulator
      else return { ...accumulator, [current]: json.attributes[current] }
    }, {})

  let i = 0
  while (
    parsingRules[i] &&
    !parsingRules[i].shouldParse({ name, dataprops, json })
  ) {
    i = i + 1
  }
  if (
    parsingRules[i] &&
    parsingRules[i].shouldParse({ name, dataprops, json })
  ) {
    return parsingRules[i].parser({
      name,
      dataprops,
      json,
      hitwords,
      ignoreDiblinks,
      failSlug,
      attributes,
      key,
    })
  }
  return null
}
