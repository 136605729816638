import React from "react" // Don't use JSX in this file, as it is evaluated in node also, where babel does not run, has already cost 4 hours of Alis life :(
import { xml2json as _xml2sjon } from "xml-js"

export const slugFriendly = phrase =>
  phrase
    .replace(/[!@#$%^&*(),?":{}|<>`´:[\]~]/g, ``)
    .replace(/[.]$/g, ``)
    .replace(/[.]/g, `-`)
    .replace(/[/]/g, `-`)
    .toLowerCase()
    .replace(/[æ]/g, `a`)
    .replace(/[ø]/g, `o`)
    .replace(/[å]/g, `a`)
    .replace(/[é]/g, `e`)
    .replace(/[ü]/g, `u`)
    .split(` `)
    .filter(i => i !== `-`)
    .join(`-`)
    .replace(/[-]+[-]/g, `-`)

export const replaceHTMLEntities = str => {
  // return str.replace("&gt;", "\u003e");
  const mapObj = {
    "&gt;": "\u003e",
    "&lt;": "\u003c",
    "&amp;": "\u0026",
  }

  const pattern = new RegExp(Object.keys(mapObj).join("|"), "gi")

  return str.replace(pattern, function(matched) {
    return mapObj[matched]
  })
}

export const xml2json = xml => {
  let s = xml
    .replace(/\\"/g, `"`)
    .replace(/\n/g, "")
    .replace(/\r[ ]*/g, "")
    .replace(/&/g, "&amp;")

  let str = replaceHTMLEntities(s)

  return _xml2sjon(str, { compact: false, elementsKey: `children` })
}

export const highlightHitWord = (str, hitwords) => {
  /**
   * Takes a string and an array of string hitwords. Returns the original string or an
   * array representation of the string, where substrings that match hitwords
   * are stored in separate cells and padded with a custom <span>.
   *
   * Example
   * Arguments: str: "hello world!", hitwords ["world"]
   * Returns: ["hello", "<span ... >world</span>", "!"]
   */
  if (!hitwords || hitwords.length === 0 || !str || str.length === 0) {
    return str
  }
  const regExpStr = "(" + hitwords.join("|") + ")"
  const regExp = new RegExp(regExpStr, "i")
  let ret = str.split(regExp)
  return ret.map((el, i) =>
    el.match(regExp)
      ? React.createElement(
          "span",
          {
            key: i,
            style: {
              background: `rgba(252, 239, 199, 0.87)`, // Do NOT use this color for anything else, cuz string replaced when emailing
            },
          },
          ret[i]
        )
      : el
  )
}

export const hitblocksProcessor = ({ data, initialData }) => {
  let emnerCount = 0,
    kontoCount = 0,
    numberOfHits = 0,
    hitblockTitles = []

  let hitblocks = data.languages[0].categories.reduce(
    (accumulator, current) => {
      const hitblocks = current.hitblocks.map(hitblock => {
        hitblock.category =
          current.type === `konto` ? `kontoplan` : current.type
        hitblock.hits = hitblock.hits.map(({ title }) => title)

        hitblockTitles.push(hitblock.title)
        numberOfHits += hitblock.hits.length
        hitblock.category === `kontoplan` ? ++kontoCount : ++emnerCount
        return hitblock
      })
      accumulator = [...accumulator, ...hitblocks]
      return accumulator
    },
    []
  )

  if (initialData) {
    const splitIndex = hitblocks.findIndex(
      hitblock => hitblock.category === `kontoplan`
    )
    const kontering = hitblocks.slice(0, splitIndex)
    const konto = hitblocks.slice(splitIndex, hitblocks.length - 1)

    kontering.sort((a, b) => a.title.localeCompare(b.title))
    konto.sort((a, b) => a.title.localeCompare(b.title))

    hitblocks = [...kontering, ...konto]
  } else {
    hitblocks.sort((a, b) => {
      if (a.rank > b.rank) return -1
      if (a.rank < b.rank) return 1
      return 0
    })
  }

  return {
    hitblocks,
    emnerCount,
    kontoCount,
    numberOfHits,
    hitblockTitles,
  }
}

export const validTags = [
  "a",
  "abbr",
  "address",
  "area",
  "article",
  "aside",
  "audio",
  "b",
  "base",
  "bdi",
  "bdo",
  "big",
  "blockquote",
  "body",
  "br",
  "button",
  "canvas",
  "caption",
  "cite",
  "code",
  "col",
  "colgroup",
  "data",
  "datalist",
  "dd",
  "del",
  "details",
  "dfn",
  "dialog",
  "div",
  "dl",
  "dt",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "footer",
  "form",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "head",
  "header",
  "hgroup",
  "hr",
  "html",
  "i",
  "iframe",
  "img",
  "input",
  "ins",
  "kbd",
  "keygen",
  "label",
  "legend",
  "li",
  "link",
  "main",
  "map",
  "mark",
  "menu",
  "menuitem",
  "meta",
  "meter",
  "nav",
  "noscript",
  "object",
  "ol",
  "optgroup",
  "option",
  "output",
  "p",
  "param",
  "picture",
  "pre",
  "progress",
  "q",
  "rp",
  "rt",
  "ruby",
  "s",
  "samp",
  "script",
  "section",
  "select",
  "small",
  "source",
  "span",
  "strong",
  "style",
  "sub",
  "summary",
  "sup",
  "table",
  "tbody",
  "td",
  "textarea",
  "tfoot",
  "th",
  "thead",
  "time",
  "title",
  "tr",
  "track",
  "u",
  "ul",
  "var",
  "video",
  "wbr",
  "circle",
  "clipPath",
  "defs",
  "ellipse",
  "g",
  "image",
  "line",
  "linearGradient",
  "mask",
  "path",
  "pattern",
  "polygon",
  "polyline",
  "radialGradient",
  "rect",
  "stop",
  "svg",
  "text",
  "tspan",
]

export const voidTags = [
  "area",
  "base",
  "br",
  "col",
  "command",
  "embed",
  "hr",
  "img",
  "input",
  "keygen",
  "link",
  "meta",
  "param",
  "source",
  "track",
  "wbr",
]

export const validParentElements = [
  "body",
  "blockquote",
  "button",
  "dd",
  "del",
  "div",
  "fieldset",
  "form",
  "iframe",
  "ins",
  "li",
  "map",
  "noframes",
  "noscript",
  "object",
  "td",
  "th",
]

// Sebastians lurerier

export const months = [
  "januar",
  "februar",
  "mars",
  "april",
  "mai",
  "juni",
  "juli",
  "august",
  "september",
  "oktober",
  "november",
  "desember",
]

export const compareDates = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

export const printMe = printRef => {
  // The cleanup for this is in an eventListener "afterprint"
  // It is implemented in gatsby-browser: onInitialClientRender
  // also see print styles in @global rule in page-wrapper.js

  let printContainer = document.createElement(`div`)
  printContainer.setAttribute(`id`, `print-container`)
  printContainer.innerHTML = printRef.current.outerHTML
  document.querySelector(`body`).appendChild(printContainer)

  window.print()
}
