import {
  HIT_TOGGLED,
  HIT_OPENED,
  HIT_SCROLL,
  HIT_VISIBLE,
  HIT_NOT_VISIBLE,
} from "../actions/hit"

export function expandedHits(state = [], action) {
  switch (action.type) {
    case HIT_OPENED:
      if (state.includes(action.hit)) return state
      return [...state, action.hit]
    case HIT_TOGGLED:
      const index = state.indexOf(action.hit)
      if (index === -1) return [...state, action.hit]
      return [...state.slice(0, index), ...state.slice(index + 1)]
    default:
      return state
  }
}

export function hitToScroll(state = ``, action) {
  switch (action.type) {
    case HIT_SCROLL:
      return action.hitSlug
    default:
      return state
  }
}

export function visibleHits(state = [], action) {
  switch (action.type) {
    case HIT_VISIBLE:
      if (state.includes(action.hit)) return state
      return [...state, action.hit]
    case HIT_NOT_VISIBLE:
      const index = state.indexOf(action.hit)
      if (index === -1) return state
      return [...state.slice(0, index), ...state.slice(index + 1)]
    default:
      return state
  }
}
