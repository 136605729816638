import {
  ITEMS_HAS_ERRORED,
  ITEMS_IS_LOADING,
  ITEMS_FILTERED_BY_CATEGORY,
  ITEMS_CATEGORY_COUNT,
  ITEMS_SHOW_CATEGORY_COUNT,
  ITEMS_QUERY_SUCCESS,
  ITEMS_RESET,
} from "../actions/items"

import { initialItems } from "../../../generated-data/initial-items"
const { hitblocks, emnerCount, kontoCount } = JSON.parse(initialItems)

export function itemsHasErrored(state = false, action) {
  switch (action.type) {
    case ITEMS_HAS_ERRORED:
      return action.hasErrored
    default:
      return state
  }
}

export function itemsIsLoading(state = false, action) {
  switch (action.type) {
    case ITEMS_IS_LOADING:
      return action.isLoading
    default:
      return state
  }
}

export function itemsCategoryFilter(state = `all`, action) {
  switch (action.type) {
    case ITEMS_FILTERED_BY_CATEGORY:
      return action.category
    default:
      return state
  }
}

export function itemsCategoryCount(state = { emnerCount, kontoCount }, action) {
  switch (action.type) {
    case ITEMS_CATEGORY_COUNT:
      return action.countObj
    default:
      return state
  }
}

export function itemsShowCategoryCount(state = false, action) {
  switch (action.type) {
    case ITEMS_SHOW_CATEGORY_COUNT:
      return action.showOrNot
    default:
      return state
  }
}

export function items(state = hitblocks || [], action) {
  switch (action.type) {
    case ITEMS_RESET:
      return hitblocks
    case ITEMS_QUERY_SUCCESS:
      return action.items
    default:
      return state
  }
}
