import { CATEGORY_EXPANDED, CATEGORY_CLOSED } from "../actions/category"

export function expandedCategories(state = [], action) {
  switch (action.type) {
    case CATEGORY_EXPANDED:
      return state.includes(action.category)
        ? state
        : [...state, action.category]
    case CATEGORY_CLOSED:
      const index = state.indexOf(action.category)
      if (index === -1) return state
      return [...state.slice(0, index), ...state.slice(index + 1)]
    default:
      return state
  }
}
