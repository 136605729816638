import { RENDER_FOR_EMAIL } from "../actions/email"

export function renderForEmail(state = false, action) {
  switch (action.type) {
    case RENDER_FOR_EMAIL:
      return action.emailOrNot
    default:
      return state
  }
}
