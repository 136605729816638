import React, { createRef, useLayoutEffect, useEffect, useState } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"
import { Link as RLink } from "@reach/router"

import Hit from "./hit"

import { hitToggled, hitOpened, hitScroll } from "../state/actions/hit"
import { hitblockToggled, hitblockOpened } from "../state/actions/hitblock"
import { searchExecuted } from "../state/actions/search"
import { slugFriendly } from "../../utils"
import { colors, sizes, borders, fontSizes } from "../style/design-system"

const useStyles = createUseStyles({
  title: {
    fontWeight: `400`,
    margin: 0,
    fontSize: fontSizes["md"],
  },
  openTitle: {
    fontWeight: 700,
    fontSize: fontSizes["md"],
    margin: `${sizes["xl"]} 0 ${sizes["md"]} 0`,
  },
  openContainer: {
    color: colors["gray"]["600"],
    paddingLeft: sizes["md"],
    borderLeft: borders["regular"],
    marginLeft: `-${sizes["md"]}`,
    "&:first-child > a > h3": {
      marginTop: 0,
    },
  },
  closedContainer: {
    color: colors["gray"]["600"],
    margin: `${sizes["xl"]} 0`,
    "&:first-child": {
      marginTop: 0,
    },
  },
  hits: {
    listStyle: `none`,
    margin: `0 0 0 ${sizes["xl"]}`,
  },
  link: {
    color: `inherit`,
    textDecoration: `none`,
  },
})

const HitBlock = ({
  closeSidebar,
  hitblock,
  searchExecutedTimes,
  fromSearch,
  expandedHitblocks,
  toggleHitblock,
  openHitblock,
  toggleHit,
  openHit,
  scrollHit,
}) => {
  const {
    title,
    openTitle,
    openContainer,
    closedContainer,
    hits,
    link,
  } = useStyles()

  let open = expandedHitblocks.includes(hitblock.title)

  useEffect(() => {
    if (open && hitblock.hits.length === 1) openHit(hitblock.hits[0])
  }, [open])

  const hitblockSlug = `/${slugFriendly(hitblock.category)}/${slugFriendly(
    hitblock.title
  )}`

  const containerRef = createRef()

  const [clickCounter, setClickCounter] = useState(Math.random().toFixed(3))

  useLayoutEffect(() => {
    const pathParts = window
      .decodeURIComponent(window.location.pathname)
      .split("/")

    const categoryPart = pathParts[1]
    const hitblockPart = pathParts[2]

    if (
      !searchExecutedTimes &&
      categoryPart === slugFriendly(hitblock.category) &&
      hitblockPart === slugFriendly(hitblock.title)
    ) {
      openHitblock(hitblock.title)

      containerRef.current.scrollIntoView()
      document.getElementById("result-list").scrollTop -= 35
    }
  }, [])
  return (
    <div className={open ? openContainer : closedContainer} ref={containerRef}>
      <RLink
        to={hitblockSlug}
        className={link}
        onClick={() => {
          fromSearch(false)
          toggleHitblock(hitblock.title)
        }}
      >
        <h3 className={open ? openTitle : title}>{hitblock.title}</h3>
      </RLink>

      {open ? (
        <ul className={hits}>
          {hitblock.hits.map(hit => (
            <Hit
              key={hit}
              closeSidebar={closeSidebar}
              category={hitblock.category}
              hitblock={hitblock.title}
              hit={hit}
              hitblockSlug={hitblockSlug}
              clickCounter={clickCounter}
              setClickCounter={setClickCounter}
              scrollHit={scrollHit}
              toggleHit={toggleHit}
              openHit={openHit}
              fromSearch={fromSearch}
              searchExecutedTimes={searchExecutedTimes}
            />
          ))}
          <span></span>
        </ul>
      ) : null}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    expandedHitblocks: state.expandedHitblocks,
    searchExecutedTimes: state.searchExecutedTimes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleHit: hit => dispatch(hitToggled(hit)),
    openHit: hit => dispatch(hitOpened(hit)),
    toggleHitblock: hitblock => dispatch(hitblockToggled(hitblock)),
    openHitblock: hitblock => dispatch(hitblockOpened(hitblock)),
    fromSearch: bool => dispatch(searchExecuted(bool)),
    scrollHit: slug => dispatch(hitScroll(slug)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HitBlock)
