import React from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"

import { highlightHitWord } from "../../utils"

import { diblinkShow, diblinkClose } from "../state/actions/dib-link"
import { colorAliases } from "../style/design-system"

const useStyles = createUseStyles({
  diblink: {
    color: colorAliases["linkColor"],
    borderBottom: `1px dashed ${colorAliases["linkColor"]}`,
    cursor: `pointer`,
    fontWeight: 400,
  },
})

const DibLink = ({
  refId,
  topicId,
  segmentId,
  type,
  hitwords,
  text,
  id,
  diblinkToShow,
  showDiblink,
  closeDiblink,
}) => {
  const { diblink } = useStyles()

  const handleClick = () => {
    if (
      diblinkToShow &&
      diblinkToShow.type === type &&
      diblinkToShow.refId === refId &&
      diblinkToShow.topicId === topicId &&
      diblinkToShow.segmentId === segmentId &&
      diblinkToShow.id === id
    )
      closeDiblink()
    else showDiblink({ type, refId, topicId, segmentId, id })
  }
  return (
    <span
      onClick={() => handleClick()}
      data-testid="diblink"
      className={diblink}
    >
      {highlightHitWord(text, hitwords)}
    </span>
  )
}

const mapStateToProps = state => {
  return {
    diblinkToShow: state.diblinkToShow,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showDiblink: linkObj => dispatch(diblinkShow(linkObj)),
    closeDiblink: () => dispatch(diblinkClose()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DibLink)
