import React, { useState } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"
import jsonToReact from "./json-to-react"
import ChevronUp from "./chevron-up"
import ChevronDown from "./chevron-down"
import nanoid from "nanoid"

import {
  sizes,
  borderRadius,
  borders,
  colorAliases,
  colors,
} from "../style/design-system"

const useStyles = createUseStyles({
  box: {
    padding: `${sizes["xxs"]} 0`,
    borderRadius: borderRadius["sm"],
    margin: `${sizes["xs"]} 0`,
  },
  openBox: {
    borderTop: borders["contentRegular"],
    borderBottom: borders["contentRegular"],
  },
  title: {
    margin: 0,
    cursor: `pointer`,
    display: `flex`,
    alignItems: `center`,
    fontWeight: 700,
  },
  chevron: {
    marginLeft: sizes["xs"],
  },
})

const Box = ({ boxContent, failSlug, ignoreDiblinks, renderForEmail }) => {
  const { box, openBox, title, chevron } = useStyles()

  const [open, setOpen] = useState(false)
  const [id] = useState(nanoid())

  const hasHeader =
    boxContent.slice(0, 1)[0] &&
    boxContent.slice(0, 1)[0].children &&
    boxContent.slice(0, 1)[0].children[0].text
  let header
  if (hasHeader) {
    header = boxContent.slice(0, 1)[0].children[0].text
  } else {
    header = `Eksempel`
    // console.log(`I dont have no header`, failSlug)
  }

  return (
    <div
      className={open ? [box, openBox].join(` `) : box}
      style={
        renderForEmail
          ? { backgroundColor: colors["gray"]["50"], padding: 15 }
          : {}
      }
    >
      <span className={title} onClick={() => setOpen(!open)}>
        {header}{" "}
        {open && !renderForEmail ? (
          <ChevronUp
            className={chevron}
            invert={true}
            color={colorAliases["darkColor"]}
          />
        ) : !renderForEmail ? (
          <ChevronDown
            className={chevron}
            invert={true}
            color={colorAliases["darkColor"]}
          />
        ) : null}{" "}
      </span>
      {open || renderForEmail
        ? jsonToReact({
            json: {
              name: `div`,
              children: hasHeader ? boxContent.slice(1) : boxContent.slice(0),
            },
            hitwords: null,
            ignoreDiblinks,
            failSlug,
            key: id,
          })
        : null}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    renderForEmail: state.renderForEmail,
  }
}

export default connect(mapStateToProps, null)(Box)
