import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"

import Hitblock from "./hitblock"

import { sizes, colors } from "../style/design-system"

const useStyles = createUseStyles({
  list: {
    padding: `${sizes["xl"]} ${sizes["xl"]} 0 ${sizes["xl"]}`,
  },
  errorMessage: {
    fontWeight: 400,
    margin: 0,
    color: colors["gray2"]["500"],
  },
})

const ResultList = ({
  closeSidebar,
  items,
  itemsCategoryFilter,
  itemsHasErrored,
  searchExecutedTimes,
  className,
}) => {
  const { list, errorMessage } = useStyles()

  const ref = useRef()
  useEffect(() => {
    if (searchExecutedTimes) ref.current.scrollTop = 0
  }, [searchExecutedTimes])

  return (
    <div ref={ref} id="result-list" className={[className, list].join(` `)}>
      {itemsHasErrored ? (
        <h3 className={errorMessage}>Ingen treff på søket ditt</h3>
      ) : (
        items.map(item => {
          let ret =
            itemsCategoryFilter === `all` ||
            itemsCategoryFilter === item.category ? (
              <Hitblock
                closeSidebar={closeSidebar}
                hitblock={item}
                key={`${item.category}-${item.title}`}
              />
            ) : null
          return ret
        })
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    items: state.items,
    itemsCategoryFilter: state.itemsCategoryFilter,
    searchExecutedTimes: state.searchExecutedTimes,
    itemsHasErrored: state.itemsHasErrored,
  }
}

export default connect(mapStateToProps)(ResultList)
